import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup'
import { ChangePasswordDto, useAuth } from '../../../modules/auth';
import { toast } from 'react-toastify';

type DashboardChangePasswordProps = {
  onDismiss: () => void,
}

const changeSchema = Yup.object().shape({
  new_password: Yup.string().required('New password required'),
  confirm_new_password: Yup.string().required('Retype new password required')
})

const DashboardChangePassword: FC<DashboardChangePasswordProps> = ({ onDismiss }) => {
  let { isLoadingChangePassword, currentUser, onChangePassword, setCurrentUser } = useAuth()
  const [isShowPassword, setShowPassword] = useState(false);
  const formik = useFormik<ChangePasswordDto>({
    initialValues: {
      confirm_new_password: '',
      new_password: '',
    },
    validationSchema: changeSchema,
    onSubmit: async (values, { setFieldError }) => {
      if (values.confirm_new_password !== values.new_password) {
        setFieldError('confirm_new_password', 'double check your password');
        setFieldError('new_password', 'double check your password');
        toast('double check your password', { type: 'error' });
      } else {
        await onChangePassword(values);

        if (currentUser) {
          currentUser.is_password_has_changed = 1;
          setCurrentUser(currentUser);
        }

        onDismiss();
      }
    }
  });

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_location'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-600px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              <div>
                <span className="text-dark fw-bold text-dark d-block">
                  Change Password
                </span>
                <span className="text-muted fw-regular text-muted d-block">
                  Please change your password for safety purposes on first time login.
                </span>
              </div>
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5'>
              {/* begin::Form group */}
              <div className='fv-row mb-3'>
                <div className='input-group'>
                  <input
                    type={!isShowPassword ? 'password' : 'text'}
                    placeholder='New Password'
                    autoComplete='off'
                    {...formik.getFieldProps('new_password')}
                    className='form-control form-control-solid'
                  />
                  <button type="button" className="btn btn-icon btn-light" onClick={() => setShowPassword(!isShowPassword)}>
                    <i className={`bi bi-eye${!isShowPassword ? '-slash' : ''} fs-2`} />
                  </button>
                </div>
                {formik.touched.new_password && formik.errors.new_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.new_password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='fv-row mt-8'>
                <div className='input-group'>
                  <input
                    type={!isShowPassword ? 'password' : 'text'}
                    placeholder='Repeat New Password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_new_password')}
                    className='form-control form-control-solid'
                  />
                  <button type="button" className="btn btn-icon btn-light" onClick={() => setShowPassword(!isShowPassword)}>
                    <i className={`bi bi-eye${!isShowPassword ? '-slash' : ''} fs-2`} />
                  </button>
                </div>
                {formik.touched.confirm_new_password && formik.errors.confirm_new_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirm_new_password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className='d-flex flex-row justify-content-end mt-10'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-light mx-8'
                  onClick={onDismiss}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-danger'
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => formik.handleSubmit()}
                >
                  {!isLoadingChangePassword && <span className='indicator-label'>Change Password</span>}
                  {isLoadingChangePassword && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Action */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
};

export default DashboardChangePassword;