import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { FC, Suspense, lazy, Fragment } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const { menus, isBrandRole } = useAuth()
  const BrandPage = lazy(() => import('../modules/brand/BrandPage'))
  const CategoryPage = lazy(() => import('../modules/category/CategoryPage'))
  const ProductPage = lazy(() => import('../modules/product/ProductPage'))
  const WarehousePage = lazy(() => import('../modules/warehouse/WarehousePage'))
  const BrandTransactionPage = lazy(() => import('../modules/brand-transaction/BrandTransactionPage'))
  const TransactionPage = lazy(() => import('../modules/transaction/TransactionPage'))
  const FlashSalePage = lazy(() => import('../modules/flash-sale/FlashSalePage'))
  const ReferralCampaignPage = lazy(() => import('../modules/referral-campaign/ReferralCampaignPage'))
  const WithdrawalPage = lazy(() => import('../modules/withdrawal/WithdrawalPage'))
  const BannerPage = lazy(() => import('../modules/banner/BannerPage'))
  const CampaignPopupPage = lazy(() => import('../modules/campaign-popup/CampaignPopupPage'))
  const EventPage = lazy(() => import('../modules/event/EventPage'))
  const BrandWithdrawalPage = lazy(() => import('../modules/brand-withdrawal/BrandWithdrawalPage'))
  const WithdrawalBrandPage = lazy(() => import('../modules/withdrawal/WithdrawalBrandPage'))
  const ReferralRedeemPage = lazy(() => import('../modules/referral-redeem/ReferralRedeemPage'))
  const CustomerManagementPage = lazy(() => import('../modules/customer-management/CustomerManagementPage'))
  const VoucherPage = lazy(() => import('../modules/voucher/VoucherPage'))
  const VoucherApprovalPage = lazy(() => import('../modules/voucher/VoucherApprovalPage'))

  const isAbleToRender = (arrayData: any = [], key: any) => arrayData?.includes(key);

  const getRouteGroupBasedPermission = (groupKey, subRoutesGroup) => {
    const routesGroup = {
      dashboard: () => {
        return <Route
          path='dashboard'
          element={<DashboardWrapper />}
        />
      },
      'product-management': () => {
        const subMenus = subRoutesGroup['product-management']
        return <Route
          path='product-management/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'product-list') && <ProductPage />}
              {isAbleToRender(subMenus, 'category') && <CategoryPage />}
            </SuspensedView>
          }
        />
      },
      'brand-management': () => {
        return <Route
          path='brand-management/*'
          element={
            <SuspensedView>
              <BrandPage />
            </SuspensedView>
          }
        />
      },
      'warehouse': () => {
        return <Route
          path='warehouse/*'
          element={
            <SuspensedView>
              <WarehousePage />
            </SuspensedView>
          }
        />
      },
      'transaction': () => {
        const subMenus = subRoutesGroup['transaction']
        return <Route
          path='transaction/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'brand-transactions') && <BrandTransactionPage />}
              {isAbleToRender(subMenus, 'customer-transactions') && <TransactionPage />}
            </SuspensedView>
          }
        />
      },
      'promotional-item': () => {
        const subMenus = subRoutesGroup['promotional-item']
        return <Route
          path='promotional-item/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'banner') && <BannerPage />}
              {isAbleToRender(subMenus, 'flash-sale') && <FlashSalePage />}
              {isAbleToRender(subMenus, 'popup-campaign') && <CampaignPopupPage />}
            </SuspensedView>
          }
        />
      },
      'reward-and-point': () => {
        const subMenus = subRoutesGroup['reward-and-point']
        return <Route
          path='reward-and-point/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'referral-campaign') && <ReferralCampaignPage />}
              {isAbleToRender(subMenus, 'referral-redeem-request') && <ReferralRedeemPage />}
            </SuspensedView>
          }
        />
      },
      'withdrawal': () => {
        const subMenus = subRoutesGroup['withdrawal']
        return <Route
          path='withdrawal/*'
          element={
            isBrandRole ? (
              <SuspensedView>
                <BrandWithdrawalPage />
              </SuspensedView>
            ) : (
              <SuspensedView>
                {isAbleToRender(subMenus, 'user') && <WithdrawalPage />}
                {isAbleToRender(subMenus, 'brand') && <WithdrawalBrandPage />}
              </SuspensedView>
            )
          }
        />
      },
      'event': () => {
        return <Route
          path='event-management/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>}
        />
      },
      'customer-management': () => {
        return <Route
          path='customer-management/*'
          element={
            <SuspensedView>
              <CustomerManagementPage />
            </SuspensedView>}
        />
      },
      'voucher': () => {
        const subMenus = subRoutesGroup['voucher']
        return <Route
          path='voucher/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'voucher-list') && <VoucherPage />}
              {isAbleToRender(subMenus, 'voucher-approval') && <VoucherApprovalPage />}
            </SuspensedView>
          }
        />
      },
    }
    let routeToRender: any = [];
    groupKey?.forEach(key => {
      if (!routesGroup?.[key]) return;
      routeToRender?.push(routesGroup?.[key]);
    })
    return routeToRender;
  }

  const routesGroupArray = menus?.map(item => item?.label);
  let subRoutesGroupArray = {}
  menus?.forEach(item => {
    Object.assign(subRoutesGroupArray, {
      [item?.label]: item?.sub_menus?.map(sub => sub?.label)
    })
  })
  const routeList = getRouteGroupBasedPermission(routesGroupArray, subRoutesGroupArray)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        {routeList?.map((item: any, index) => {
          return <Fragment key={index?.toString()}>
            {item()}
          </Fragment>
        })}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
