import axios from 'axios'
import { AuthModel, ChangePasswordDto, UserModel } from './_models'
import { BaseResponse } from '../../../common/BaseResponse'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER = `${API_URL}/dashboard/user/auth`
export const LOGIN_URL = `${API_URL}/dashboard/login`
export const GET_USER_PERMISSION = `${API_URL}/dashboard/user/permissions`
export const POST_CHANGE_PASSWORD_URL = `${API_URL}/dashboard/user/change-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<BaseResponse<AuthModel>>(LOGIN_URL, {
    email,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.get<BaseResponse<UserModel>>(GET_USER)
}

export function getUserPermission(params) {
  return axios.get<any>(GET_USER_PERMISSION, { params })
}

export function postChangePassword(params: ChangePasswordDto) {
  return axios.post<BaseResponse<any>>(POST_CHANGE_PASSWORD_URL, params);
}