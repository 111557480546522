import { createContext, useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalProviderContext = createContext<any>({});

const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    title: 'Modal Title',
    description: 'Are you sure want to delete this data?',
    actionButtonVariant: 'danger',
    actionButtonLabel: 'Confirm',
    action: () => { }
  })
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)
    modalData?.action && modalData?.action();
  };
  const handleShow = () => setShow(true);

  const openModal = ({ title, description, actionButtonVariant = 'danger', actionButtonLabel = 'Confirm', action }) => {
    setModalData({ title, description, actionButtonVariant, actionButtonLabel, action })
    handleShow();
  }

  return <ModalProviderContext.Provider value={{ openModal }}>
    {children}
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalData?.description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button variant={modalData?.actionButtonVariant} onClick={handleClose}>
          {modalData?.actionButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  </ModalProviderContext.Provider>
}

const useModal = () => useContext(ModalProviderContext)

export { ModalProvider, useModal }