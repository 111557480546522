/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
// auth
import { useAuth } from '../../../../../app/modules/auth'

const getIconByGroup = (group) => {
  const defaultIcon = '/media/svg/side-menu/dashboard.svg'
  const icon = {
    dashboard: '/media/svg/side-menu/dashboard.svg',
    'product-management': '/media/svg/side-menu/product-management.svg',
    transaction: '/media/svg/side-menu/transaction.svg',
    withdrawal: '/media/svg/side-menu/withdrawal.svg',
    'brand-management': '/media/svg/side-menu/brand.svg',
    'customer-management': '/media/svg/side-menu/customer-management.svg',
    'user-management': '/media/svg/side-menu/user-management.svg',
    warehouse: '/media/svg/side-menu/warehouse.svg',
    'promotional-item': '/media/svg/side-menu/promo-item.svg',
    'rewards-and-points': '/media/svg/side-menu/redeem-point.svg',
    'event-management': '/media/svg/side-menu/event.svg',
    'voucher': '/media/svg/side-menu/ticket.svg',
  }
  return icon?.[group] || defaultIcon
}

const SidebarMenuMain = () => {
  const { menus } = useAuth();

  return (
    <>
      {menus?.map(item => {
        const icon = getIconByGroup(item?.label)
        const isContainSubmenus = Boolean(item?.sub_menus?.length)

        if (!isContainSubmenus) {
          return <SidebarMenuItem
            isSvg
            key={item?.id}
            to={item?.path}
            title={item?.group}
            icon={icon}
            fontIcon='bi-archive'
          />
        }

        return <SidebarMenuItemWithSub
          isSvg
          key={item?.id}
          to={item?.path}
          title={item?.group}
          icon={icon}
          fontIcon='bi-archive'
        >
          {item?.sub_menus?.map(submenu => {
            const isHasSubmenus = Boolean(submenu?.sub_menus?.length)
            if (isHasSubmenus) {
              return <SidebarMenuItemWithSub isSvg key={submenu?.id} to={submenu?.path} title={submenu?.group} hasBullet={true}>
                {submenu?.sub_menus?.map(obj => {
                  return <SidebarMenuItem key={obj?.id} to={obj?.path} title={obj?.group} hasBullet={true} />
                })}
              </SidebarMenuItemWithSub>
            }
            return <SidebarMenuItem key={submenu?.id} to={submenu?.path} title={submenu?.group} hasBullet={true} />
          })}
        </SidebarMenuItemWithSub>
      })}

      {/* <SidebarMenuItemWithSub
        isSvg
        to='/reward-and-point'
        icon='/media/svg/side-menu/redeem-point.svg'
        title='Reward and Points'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem
          to='/reward-and-point/referral-campaign' title='Referral Campaign' hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export { SidebarMenuMain }
